<template>
  <div>
    <eden-page-header
      :title="'One-time Order Menu'"
      :subtitle="menuItems.name"
    />

    <eden-table-actions
      :title="menuItems.name"
      @search="search"
      class="menu-actions"
    >
      <template slot="actions">
        <div class="page--head-actions is-flex is-flex-wrap align-center">
          <el-dropdown
            v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
            :placement="'bottom'"
            @command="command"
          >
            <el-button type="plain">
              Actions
              <i class="eden-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="eden-icon--edit" command="edit"
                >Edit Menu</el-dropdown-item
              >
              <el-dropdown-item icon="eden-icon--eye" command="make-active"
                >Make active menu</el-dropdown-item
              >
              <el-dropdown-item icon="eden-icon--eye" command="publish-menu"
                >Publish Menu</el-dropdown-item
              >
              <el-dropdown-item
                v-if="allowAccessFor(['superadmin'])"
                icon="eden-icon--copy"
                command="duplicate"
                >Duplicate menu</el-dropdown-item
              >
              <el-dropdown-item
                icon="eden-icon--eye"
                command="same-day-delivery-logs"
                >Same-day delivery combo portions log</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </eden-table-actions>
    <el-form>
      <eden-loader v-if="loading" />
      <template v-else>
        <el-row
          class="firstMeal"
          v-for="(meal, index) in menu"
          :key="`12-${index}`"
          type="flex"
        >
          <el-col :md="24">
            <el-form-item>
              <div class="card mb-10">
                <div class="header mb-10">
                  <span class="text-bold"
                    >{{ meal?.main_meal_name }}
                    <!-- <span class="tag">
                      <el-tag type="info"
                        >{{ meal?.category_name }} cut-off</el-tag
                      >
                    </span> -->
                  </span>
                </div>

                <div class="card-container" v-if="meal.combos.length">
                  <el-row type="flex" class="flex-wrap font-sm justify-between">
                    <el-col v-for="(combo, i) in meal.combos" :key="i" :md="12">
                      <div class="combo-card">
                        <div class="is-flex is-align-center">
                          <div class="eden-meal--image">
                            <img
                              v-if="combo.image_url != null"
                              :src="getThumbnailFromCloudinary(combo.image_url)"
                              alt="Image"
                            />
                            <img
                              v-else
                              :src="getImage('default/image-upload-icon.svg')"
                              :alt="'Image'"
                              :class="'default meal-image'"
                            />
                          </div>
                          <div class="additional-info">
                            <div class="text-bold mr-10">
                              {{ combo.comboName }}
                            </div>
                            <div class="combo-text">
                              {{
                                validateCalorie(combo.calorie)
                                  ? `${combo.calorie} calories`
                                  : "No calories added"
                              }}
                            </div>
                            <div class="combo-text">
                              {{
                                validateCombos(combo.tags)
                                  ? `Food tags: ${formatToTitleCase(
                                      convertFoodTagsToString(combo.tags),
                                    )}`
                                  : "No food tags added"
                              }}
                            </div>

                            <div class="combo-text text-bold">
                              Price: {{
                                formatPrice(combo.price)
                              }}
                            </div>

                          </div>
                          <div class="text-cursor">
                            <span
                              @click="editCombo(combo, i, meal, menuTimeKey)"
                              class="eden-icon-edit font-sm mr-1"
                            ></span>
                          </div>
                        </div>

                        <div class="divider" v-if="location !== 'KE'">
                          <div class="is-flex py-2 mt-1 is-align-center">
                            <div class="is-inline ml-1 font-base">
                              <p class="combo-text">
                                Total portions available:
                                <span class="text-bold">{{
                                  combo.total_available_portion
                                }}</span>
                              </p>
                              <div v-for="mfc in combo.portions" :key="mfc.id">
                                <p class="combo-text">
                                Portions left for {{ mfc.mfc.name }}:
                                <span class="text-bold">{{
                                  mfc.current_quantity
                                }}</span>
                              </p>
                              </div>
                              <p ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div
                  class="is-flex is-align-center font-sm justify-between"
                  v-else
                >
                  <div class="combo-card is-flex is-align-center w-50">
                    <div class="eden-meal--image">
                      <img
                        v-if="meal.main_meal_img != null"
                        :src="getThumbnailFromCloudinary(meal.main_meal_img)"
                        alt="Image"
                      />
                      <img
                        v-else
                        :src="getImage('default/image-upload-icon.svg')"
                        :alt="'Image'"
                        :class="'default meal-image'"
                      />
                    </div>
                    <div class="additional-info">
                      <div class="text-bold mr-10">
                        {{ meal.main_meal_name }}
                      </div>
                      <div>
                        {{
                          validateCombos(meal?.preset_combos?.[0]?.calorie)
                            ? `${meal?.preset_combos[0]?.calorie} calories`
                            : "No calories added"
                        }}
                      </div>
                      <div>
                        {{
                          validateCombos(meal?.preset_combos)
                            ? `Food tags: ${formatToTitleCase(
                                convertFoodTagsToString(
                                  meal?.preset_combos[0]?.food_tags,
                                ),
                              )}`
                            : "No food tags added"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </el-form>
    <one-time-combination-form
      :show.sync="showComboForm"
      :action="'edit'"
      :item="comboToEdit"
      :presetComboIndex="presetComboIndex"
      @newCombos="updateCombinations"
      @success="updateCombinations"
    />
    <one-time-menu-log
      :logs="logDetails"
      :show.sync="showDetails"
      :page="logPagination.page"
      :total="logPagination.total"
      :per-page="logPagination.perPage"
      @see-more="logPagination.page++"
    />
  </div>
</template>

<script>
import onetime from "@/requests/services/meal/onetime/index";

export default {
  name: "OneTimeMenu",
  components: {
    OneTimeMenuLog: () =>
      import("@/components/Services/Meal/MealOneTimeOrder/OneTimeMenuLog"),
    OneTimeCombinationForm: () =>
      import(
        "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/OneTimeCombinationForm"
      ),
  },
  data() {
    return {
      loading: false,
      meals: [],
      showDetails: false,
      menu: {},
      logDetails: [],
      logPagination: { page: 1, total: 1, perPage: 20 },
      menuItems: {},
      showComboForm: false,
      comboIndex: -1,
      mealIndex: -1,
      presetComboIndex: 0,
      comboToEdit: {},
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    service() {
      return this.$route.params.service;
    },
    menuId() {
      return this.$route.params.id;
    },
  },
  watch: {
    "logPagination.page"(oldV, newV) {
      if (oldV != newV) {
        this.sameDayDeliveryLogs();
      }
    },
  },
  created() {
    this.getMenu();
  },
  methods: {
    editCombo(combo, index, meal, key) {
      this.comboToEdit = {
        ...combo,
        meal_id: meal.id,
        meal_type: meal?.extra?.meal_type,
        extra: meal.extra,
        side_types: meal.side_types,
      };
      this.selectionTimeInAction = key;
      this.presetComboIndex = index;
      this.showComboForm = true;
    },
    updateCombinations(newCombo) {
      const updatedCombo = newCombo.data;
      updatedCombo.comboName = newCombo.comboName; // Append comboName to the updated combo object
      // Search for the meal and combo that should be updated
      for (let i = 0; i < this.menu.length; i++) {
        for (let j = 0; j < this.menu[i].combos.length; j++) {
          // Assuming each combo has a unique id property
          if (this.menu[i].combos[j].id === updatedCombo.id) {
            // Replace the old combo with the updated one
            this.menu[i].combos[j] = updatedCombo;
            return; // Exit the function after the update is done
          }
        }
      }
    },

    validateCalorie(calorie) {
      return calorie && Number(calorie);
    },
    convertFoodTagsToString(tags) {
      try {
        if (!tags || !tags.length) {
          return "";
        }
        let tagList;
        if (Array.isArray(tags)) {
          tagList = tags;
        }
        if (typeof tags === "string") {
          tagList = JSON.parse(tags);
        }
        return tagList.flat().join(", ");
      } catch (error) {
        return "";
      }
    },
    validateCombos(combos) {
      if (!combos || !combos.length) {
        return false;
      }
      if (
        typeof combos === "string" &&
        combos.startsWith("[") &&
        combos.endsWith("]")
      ) {
        const comboList = JSON.parse(combos).flat();
        // remove null or empty string from the list
        const cleanedComboList = comboList.filter(
          (item) => item !== null && item !== "" && item !== ",",
        );
        if (!cleanedComboList.length) {
          return false;
        }
      }
      return Object.values(combos).every((value) => {
        if (Array.isArray(value)) {
          // check the array length after filtering out null or empty string
          return Boolean(
            value.filter((item) => item !== null && item !== "").length,
          );
        }

        return Boolean(value);
      });
    },
    getMenu() {
      this.loading = true;
      onetime
        .get(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.menuItems = response.data.data;
            const sortedArry = response.data.data.item_groups.sort((a, b) =>
              a.menu_item_category_id > b.menu_item_category_id
                ? 1
                : a.menu_item_category_id < b.menu_item_category_id
                ? -1
                : 0,
            );
            this.menu = sortedArry.map((item) => ({
              ...item,
              combos: item.menu_combo_items.map((item) => {
                return {
                  ...item,
                  comboName: item.side_items
                    .map((combo) => combo.meal_name)
                    .join(", "),
                };
              }),
            }));
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    search(query) {
      if (query === "") {
        this.getMenu();
        return;
      }
      this.loading = true;
      onetime
        .search(this.menuId, query)
        .then((response) => {
          this.loading = false;
          const sortedArry = response.data.data.item_groups.sort((a, b) =>
            a.menu_item_category_id > b.menu_item_category_id
              ? 1
              : a.menu_item_category_id < b.menu_item_category_id
              ? -1
              : 0,
          );
          this.menu = sortedArry.map((item) => ({
            ...item,
            combos: item.menu_combo_items.map((item) => {
              return {
                ...item,
                comboName: item.side_items
                  .map((combo) => combo.meal_name)
                  .join(", "),
              };
            }),
          }));
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    command(command) {
      switch (command) {
        case "edit":
          this.$route.params.service = "one-time";
          this.$router.push({ name: "services.one-time.edit" });
          break;
        case "duplicate":
          this.duplicate();
          break;
        case "make-active":
          this.setMenuActive();
          break;
          case "publish-menu":
          this.publishMenu();
          break;
          
        case "same-day-delivery-logs":
          this.sameDayDeliveryLogs();
          break;
        default:
          break;
      }
    },
    sameDayDeliveryLogs() {
      this.showDetails = true;
      onetime
        .logs(this.menuId, this.logPagination.page)
        .then((response) => {
          this.logPagination.total = response.data.data.total;
          this.logPagination.perPage = response.data.data.per_page;
          this.logDetails = [...this.logDetails, ...response.data.data.data];
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    setMenuActive() {
      onetime
        .active(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    publishMenu() {
      onetime
        .publish(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
    duplicate() {
      onetime
        .duplicate(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getMenus();
            this.$router.push({
              name: "services.service",
              params: { service: "meal" },
              query: { tab: "one-time" },
            });
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  margin: 20px 170px 20px 170px;
}
.card {
  padding: 3% 4%;
  background-color: var(--eden-grey-octonary);
  border-radius: 8px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.menu-meals {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 12px 0px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    font-size: 12px;
  }

  .meals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

    .item {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 0 8px 8px;
      width: auto;

      i {
        margin-left: 10px;
        color: #798b83;
        cursor: pointer;
      }
      &.invalid {
        color: #e36262;
        i {
          color: #e36262;
        }
      }
    }
  }
}
.eden-meal--image {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: #f4f4f9;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  + div {
    width: 70%;
  }
}
.combo-card {
  background-color: var(--white);
  padding: 8px;
  border: 1px solid #cbd8d2;
  border-radius: 8px;
  width: 311px;
  margin-top: 9px;
  justify-content: space-between;
  .divider {
    border-top: 1px solid #e2e9e6;
    margin-top: 12px;
  }
  .combo-text {
    color: var(--eden-grey-tertiary);
  }
}
.additional-info {
  line-height: 18px;
}

.meal-image {
  background: #e9fbf2;
}

.tag {
  margin-left: 12px;
}

@media (max-width: 768px) {
  .el-form {
    margin: 0;
  }

  .combo-card {
    width: 278px;
  }
  .card-container {
    display: block;
  }
}
.menu-actions {
  margin: 0px 176px 0px 158px;
}
.flex-wrap {
  flex-wrap: wrap;
}
</style>
