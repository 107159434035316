import axios from "axios";

export default {
  list(params = "") {
    return axios.get(`onetime_order/menu?${params}`);
  },

  get(id) {
    return axios.get(`onetime_order/menu/${id}`);
  },

  search(id, query) {
    return axios.get(`onetime_order/menu/${id}?search=${query}`);
  },

  filter(filter) {
    return axios.get(`onetime_order/menu?filter=${filter}`);
  },

  create(payload) {
    return axios.post("onetime_order/menu", payload);
  },

  update(id, payload) {
    return axios.put(`onetime_order/menu/${id}`, payload);
  },

  updateCombo(id, comboId, payload) {
    return axios.patch(`onetime_order/menu/${id}/combo/${comboId}/edit`, payload);
  },

  delete(id) {
    return axios.delete(`onetime_order/menu/${id}`);
  },

  active(id) {
    return axios.patch(`onetime_order/menu/${id}/set_active`);
  },

  publish(id) {
    return axios.patch(`onetime_order/menu/${id}/publish`);
  },

  logs(id, page) {
    return axios.get(`onetime_order/menu/${id}/logs?page=${page}`);
  },

  duplicate(id) {
    return axios.post(`onetime_order/menu/${id}/duplicate`);
  },
  
  closingHrs(payload) {
    return axios.post(`onetime_order/working-hours`, payload);
  },

  getClosingHrs() {
    return axios.get(`onetime_order/working-hours`);
  }
};
